import React from "react";

function Login() {
    return (
        <div className="App">
            Login!
        </div>
    );
}

export default Login;